<template>
  <div>
    <b-form-group
      id="input-full-name-group"
      label="Full Name:"
      label-for="input-full-name"
    >
      <b-form-input
        id="input-full-name"
        v-model="model.fullName"
        placeholder="Full Name"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-occupation-group"
      label="Occupation:"
      label-for="input-occupation"
    >
      <b-form-input
        id="input-occupation"
        v-model="model.occupation"
        placeholder="Occupation"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-comment-group"
      label="Comment:"
      label-for="input-comment"
    >
      <b-form-textarea
        id="input-comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'WritingtypeNewEdit',
    props: ['writingtype'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.writingtype ? this.writingtype : {
          type: null,
          comment: null
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    },
  }
</script>
