<template>
  <div>
    <PageTitle v-bind:title="'Person hists'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.bib.personhist.add')" class="float-right mb-4" v-b-modal.modal-new-personhist>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new person hist
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-personhist" title="New person hist" ok-title="Save" @ok="savePersonhist" size="lg">
            <PersonhistNewEdit />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction";
  import tdColSafeHTML from "@/components/tdColSafeHTML"
  import PersonhistNewEdit from "@/components/Bibliography/PersonhistNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import tdColUUID from "@/components/tdColUUID"
  import config from '@/config.js'

  export default {
    name: 'Personhists',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, searchable: true, tdComp: tdColUUID },
          { title: this.$t('FullName'), field: 'fullName', sortable: true, searchable: true },
          { title: this.$t('Occupation'), field: 'occupation', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              return [
                { text: null, icon: 'list-ul', id: row.id, to: {
                  name: 'PersonhistDetails',
                  params: {
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Full Name', field: 'fullName', sortable: true, searchable: true },
          { title: 'Occupation', field: 'occupation', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        url: '/personhists/datatables',
        key: 1,
        searchableByColumn: true,
        config: config
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      PersonhistNewEdit
    },
    methods: {
      savePersonhist(){
        this.$store.dispatch('createPersonhist', HelpStore.item).then((response) => {
          router.push(`/personhist/${response.data.id}`)
        })
      },
      openDetails(data) {
        router.push(`/personhist/${data.id}`)
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
